import React, { Component } from 'react';
import NivoComponent from './NivoGraph'

class DocumentComponent extends Component {
  render() {
    let childComponent = null
    if (!!this.props.nivoComponent) {
      childComponent = (<NivoComponent nivoComponent={this.props.nivoComponent} data={this.props.data} graphProps={this.props.props}/>)
    } else if (!!this.props.html) {
      childComponent = (<div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>)
    } else if (this.props.components) {
     // Nested
     childComponent = this.props.components.map((childProps, i) => {
       return (
         <DocumentComponent key={i} {...childProps}/>
       )
     })
    }
    return (
      <div style={{...this.props.style, height: '100%'}}>
        { childComponent }
      </div>
    )
  }
}

export default DocumentComponent;
