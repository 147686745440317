import React, { Component } from 'react'
import { debounce } from 'lodash'
import {
  Popup,
  FullscreenControl, GeolocateControl, ScaleControl
} from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ReactMapboxGl, {
  ZoomControl, RotationControl,
  Layer, Feature
} from 'react-mapbox-gl'
import ContainerDimensions from 'react-container-dimensions'
import SVG from 'react-inlinesvg'
import { Button } from 'react-bootstrap'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import coordinatesGeocoder from './geocoding'
import Legend from '../Legend/Legend'
import DocumentComponent from './Document'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

let mapScopeLeak = null
let hoverLayers = new Set([])
let clickLayers = new Set([])

const pointCursorOn = (e) => {
  mapScopeLeak.getCanvas().style.cursor = 'pointer'
}

const resetCursor = (e) => {
  mapScopeLeak.getCanvas().style.cursor = ''
}

const hoverPopup = new Popup({
  closeButton: false, closeOnClick: false
})

const clickPopup = new Popup({
  closeButton: true, closeOnClick: false
})

const re = /{([\w\d]*)}/g

export class ReactMap extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.hideNotice = this.hideNotice.bind(this)
    this.getCamera = this.getCamera.bind(this)
    this.addControls = this.addControls.bind(this)
    this.onStyleLoad = this.onStyleLoad.bind(this)
    this.addGeocoderControl = this.addGeocoderControl.bind(this)
    this.addFullScreenControl = this.addFullScreenControl.bind(this)
    this.addGeolocateControl = this.addGeolocateControl.bind(this)
    this.addScaleControl = this.addScaleControl.bind(this)
    this.state = {
      ReactMapComponent: ReactMapboxGl({
        accessToken: this.props.mapApiKey
      }),
      fullscreenControl: null,
      geolocateControl: false,
      scaleControl: false,
      geocoderControl: null,
      geocodedAddress: null,
      showNotice: true // TODO on receive props, new notice, set true again; and set false by default
    }
  }
  hideNotice() {
    this.props.setDocument(null)
    this.setState({showNotice: true})
  }
  addControls(mapboxgl) {
    this.addGeocoderControl(mapboxgl)
    this.addFullScreenControl()
    this.addGeolocateControl()
    this.addScaleControl()
  }
  addGeocoderControl(mapboxgl) {
    if (!this.state.geocoderControl) {
      const geocoderControl = new MapboxGeocoder({
        ...this.props.geocoderProps,
        accessToken: this.props.mapApiKey,
        mapboxgl: mapboxgl,
        marker: false,
        localGeocoder: coordinatesGeocoder
      })
      geocoderControl.on('clear', () => this.setState({geocodedAddress: null}))
      // geocoderControl.on('results', ({results}) => console.log({results}))
      geocoderControl.on('result', ({result}) => {
        // console.log({result})
        this.setState({
          geocodedAddress: result.geometry
        })
      })
      // geocoderControl.on('loading', ({query}) => console.log({query}))
      geocoderControl.on('error', ({error}) => console.log({error}))
      mapScopeLeak.addControl(geocoderControl, 'top-right');
      this.setState({geocoderControl})
    }
  }
  addFullScreenControl() {
    if (!this.state.fullscreenControl) {
      const fullscreenControl = mapScopeLeak.addControl(new FullscreenControl(), "top-right");
      this.setState({fullscreenControl})
    }
  }
  addGeolocateControl() {
    if (!this.state.geolocateControl) {
      const geolocateControl = mapScopeLeak.addControl(new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
          timeout: 6000,
          maximumAge: 0
        },
        trackUserLocation: false,
        showAccuracyCircle: false
      }), "top-right");
      this.setState({geolocateControl})
    }
  }
  addScaleControl() {
    if (!this.state.scaleControl) {
      const scaleControl = mapScopeLeak.addControl(new ScaleControl({
        maxWidth: 100,
        unit: 'metric'
      }), 'bottom-left');
      this.setState({scaleControl})
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (mapScopeLeak) {
      mapScopeLeak.resize()
      Object.keys(this.props.overlays).forEach(layerId => {
        const layoutProps = this.props.overlays[layerId].layoutProps
        Object.keys(layoutProps).forEach(layoutProp => {
          const currentValue = mapScopeLeak.getLayoutProperty(layerId, layoutProp)
          if (currentValue !== layoutProps[layoutProp]) {
            mapScopeLeak.setLayoutProperty(layerId, layoutProp, layoutProps[layoutProp])
          }
        })
        const hover = this.props.overlays[layerId].hover || false
        const click = this.props.overlays[layerId].click || false
        if (hover && !hoverLayers.has(layerId)) {
          // Establish a new hover layer
          hoverLayers.add(layerId)
          mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.on('mouseleave', layerId, resetCursor)
          if (hover && typeof(hover) === "string") {
            // Enable a hovering popup
            mapScopeLeak.on('mousemove', layerId, e => {
              const feature = e.features[0]
              const coordinates = e.lngLat
              let content = hover.slice()
              let m
              do {
                m = re.exec(content)
                if (m) {
                  content = content.replace(m[0], feature.properties[m[1]])
                }
              } while (m);
              hoverPopup.setLngLat(coordinates)
                .setHTML(content)
                .addTo(mapScopeLeak);
            })
            mapScopeLeak.on('mouseleave', layerId, () => {
              hoverPopup.remove()
            })
          }
        } else if (!hover && hoverLayers.has(layerId)) {
          hoverLayers.delete(layerId)
          mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.off('mouseleave', layerId, resetCursor)
        }
        if (click && !clickLayers.has(layerId)) {
          mapScopeLeak.on('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.on('mouseleave', layerId, resetCursor)
          if (click && typeof(click) === 'string') {
            // Enable a click popup
            mapScopeLeak.on('click', layerId, e => {
              const feature = e.features[0]
              // const coordinates = e.lngLat
              let content = click.slice()
              let m
              do {
                m = re.exec(content)
                if (m) {
                  content = content.replace(m[0], feature.properties[m[1]])
                }
              } while (m);
                this.props.setDocument(content)
            })
          }
        } else if (!click && clickLayers.has(layerId)) {
          clickLayers.delete(layerId)
          mapScopeLeak.off('mouseenter', layerId, pointCursorOn)
          mapScopeLeak.off('mouseleave', layerId, resetCursor)
        }
      })
    } else if (this.props.documentHtml !== prevProps.documentHtml) {
      this.setState({showNotice: true})
    } else if (this.props.graphData !== prevProps.graphData) {
      this.setState({showNotice: true})
    }
  }
  getCamera() {
    if (!mapScopeLeak) return {}
    let {lng, lat } = mapScopeLeak.getCenter()
    let center = [lng, lat]
    let zoom = [mapScopeLeak.getZoom()]
    let bearing = mapScopeLeak.getBearing()
    let pitch = mapScopeLeak.getPitch()
    return { zoom, bearing, pitch, center }
  }
  onStyleLoad(map) {
    if (!mapScopeLeak) {
      mapScopeLeak = map
      this.props.styleLoaded({
        style: mapScopeLeak.getStyle()
      })
    }
    this.addControls(map);
  }
  render() {
    const ReactMapComponent = this.state.ReactMapComponent
    const debouncedSetCamera = debounce(() => this.props.setCamera(this.getCamera(), 3000, {leading: false, trailing: true}))
    return (
      <div id="map-concern-container" className="map-concern-container modal-container" ref={this.containerRef}>
        {
          (!this.props.overlays || !Object.keys(this.props.overlays).length) ? null
          : (
            <div className="map-element-container-outer legend-container-outer">
            {
              Object.keys(this.props.overlays).map(id => {
                const style = this.props.overlays[id].style
                if (!style) return null
                return (
                  <div className="legend-container-inner" key={`${style.id}-${style['source-layer']}`}>
                    <ContainerDimensions>
                      <Legend
                        layer={style}
                        legendText={this.props.overlays[id].legendText}
                        removeMapLayer={() => this.props.removeMapLayer(id)}
                        toggleVisibility={() => this.props.toggleVisibility(id)}
                      />
                    </ContainerDimensions>
                  </div>
                )
              })
            }
            </div>
          )
        }
        <TransitionGroup component={null}>
        {
          (this.props.document) && (
            <CSSTransition classNames="modal-transition menu-transition" timeout={600}>
              <div className="notice-modal modal-container">
                <div className="notice-modal-header">
                  <Button className="splash-close" onClick={this.hideNotice}>
                    <SVG src={this.props.closeIcon}/>
                  </Button>
                </div>
                <section className="notice-modal-body">
                  <DocumentComponent {...this.props.document}/>
                </section>
              </div>
            </CSSTransition>
          )
        }
        </TransitionGroup>

        <ReactMapComponent
          onStyleLoad={this.onStyleLoad}
          style={this.props.mapStyle}
          containerStyle={{
            height: `${this.props.height}px`,
            width: `${this.props.width}px`
          }}
          injectCss={true}
          zoom={this.props.zoom}
          center={this.props.center}
          bearing={this.props.bearing}
          pitch={this.props.pitch}
          animationOptions={this.props.animationOptions}
          movingMethod={this.props.movingMethod}
          onZoomEnd={(_map, e) => { debouncedSetCamera() }}
          onBoxZoomEnd={(_map, e) => { debouncedSetCamera() }}
          onTouchEnd={(_map, e) => { debouncedSetCamera() }}
          onPitchEnd={(_map, e) => { debouncedSetCamera() }}
          onDragEnd={(_map, e) => { debouncedSetCamera() }}
          onRotateEnd={(_map, e) => { debouncedSetCamera() }}
        >
          <ZoomControl position={'top-right'}/>
          <RotationControl position={'top-right'}/>
          {this.state.geocodedAddress && (<Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-11' }}>
            <Feature coordinates={this.state.geocodedAddress.coordinates}/>
          </Layer>)}
        </ReactMapComponent>
        {this.props.children}
      </div>
    );
  }
}
